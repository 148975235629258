<template>
  <div class="DetailsPage">
    <div class="DetailsPage-left">
      <div class="DetailsPage-left-header">
        <div>当前位置 ：</div>
        <div><router-link to="/index">首页</router-link></div>
        <div class="gang">/</div>
        <div><router-link to="/companylibrary">公司库</router-link></div>
        <div class="gang">/</div>
        <div>产品详情</div>
      </div>
      <div class="DetailsPage-left-main">
        <el-row>
          <el-col :span="8"
            ><div class="img-box">
              <img :src="productDetail.cover" /></div
          ></el-col>
          <el-col :span="16"
            ><div class="speaker-info-box">
              <h2>{{ productDetail.name }}</h2>
              <h3>{{ productDetail.company }}</h3>
              <h3>{{ productDetail.post }}</h3>
            </div></el-col
          >
        </el-row>
        <div class="main-body">
          <div class="main-body-header">
            <div class="newmessage">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-a-zu333"></use>
              </svg>
              产品介绍
            </div>
          </div>
          <div
            class="main-body-content"
            v-html="productDetail.description"
          ></div>
          <!-- <div class="main-body-header">
            <div class="newmessage">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-a-zu333"></use>
              </svg>
              参与过的会议
            </div>
          </div>
          <div class="main-body-content"></div> -->
        </div>
        <!-- <div class="title">{{ detailsdata.title }}</div>
        <div class="Article-information" v-if="detailsdata">
          <div>
            <i class="iconfont icon-leftfont-104"></i> {{ detailsdata.author }}
                            <div v-if="detailsdata.author">{{ detailsdata.author }}</div>
                <div v-else>德泰中研（转载）</div>
          </div>
          <div>
            <i class="iconfont icon-naozhong2"></i> {{ detailsdata.addTime }}
          </div>
          <div>
            <i class="iconfont icon-yanjing"></i> {{ detailsdata.click }}
          </div>
        </div>
        <div class="text-statement">{{ detailsdata.zhaiyao }}</div>
        <div class="main-text" v-html="detailsdata.content"></div> -->
        <div class="text-operation">
          <!-- <div>分享至 <i class="iconfont icon-weixin1"></i></div> -->
          <!-- <div
            @click="
              clickExpertLike(productLikeFavDetail.conferenceCompanyExhibitsId)
            "
          >
            <i class="iconfont icon-dianzan"></i>
            <span v-if="productLikeFavDetail.like != 0">{{
              productLikeFavDetail.like
            }}</span
            ><span v-else>赞</span>
          </div> -->

          <!-- <div
            @click="
              clickProjductsFav(productLikeFavDetail.conferenceCompanyExhibitsId)
            "
          >
            <i class="iconfont icon-xiai"></i>
            <span v-if="productLikeFavDetail.fav != 0">{{
              productLikeFavDetail.fav
            }}</span
            ><span v-else>收藏</span>
          </div> -->

            <div @click="clickProjductsFav(productLikeFavDetail.conferenceCompanyExhibitsId)"  v-if="productLikeFavDetail.isFav">
             <img :src="imgIcon.imgCangTrue" />
                    <span class="count">已收藏</span>
             
            </div>
            <div @click.stop="clickProjductsFav(productLikeFavDetail.conferenceCompanyExhibitsId)" v-else>
                    <!-- <i class="iconfont icon-xiai"></i> -->
                    <img :src="imgIcon.imgCang" />
                    <span class="count">收藏</span>
                  </div>
        </div>
      </div>
    </div>
    <div class="DetailsPage-right">
      <div class="consulting-header">
        <div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-a-zu339"></use>
          </svg>
          企业产品
        </div>
      </div>
      <div
        class="productsList-items"
        v-for="(item, index) in productsList"
        :key="index"
        @click="goProductsDetails(item)"
      >
        <!-- <div>
          <img :src="item.cover" alt="" />
        </div>
        <div class="item-title">{{ item.name }}</div> -->

        <div class="item-left">
          <img :src="item.cover" alt="" />
        </div>
        <div class="item-mid">
          <p>{{ item.name }}</p>
          <span class="type">自研</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imgObj } from '@/utils/const.js';
import {
  getDTExhibitsDetailById,
  GetDTExhibitsDetail,
  expertslist,
  exhibitsLike,
  exhibitsFav,
  exhibitsLikeDown,
  exhibitsFavDown,
  getDTExhibitsListByConferenceCompanyId,
  exhibitsFavList
} from "@/api";
export default {
  name: "DetailsPage",
  data() {
    return {
      queryParams: {
        //列表搜索参数
        pageIndex: 1,
        pageSize: 10,
        ids: [],
        name: "",
        text: "",
      },
      queryMyParams:{
        pageIndex: 1,
        pageSize: 10,
        conferenceId:25,
        userId: localStorage.getItem("userId") - 0,
      },
      pathtext: "",
      pathtextname: "首页",
      id: 0,
      conferenceCompanyId: 0,
      detailsdata: {},
      hotconsultlist: [],
      type: 2,
      pagedetails: "其他产品",
      productDetail: {},
      productLikeFavDetail: {},
      hotconsultlist: [],
      productsList: [],
      myExhibitList:[],
      imgIcon:imgObj
    };
  },
  methods: {
    async start() {
      // 我的展品列表
      exhibitsFavList(this.queryMyParams).then((res) => {
        console.log("我的展品", res);
        this.myExhibitList = res.data.data.item1;

      });
      await getDTExhibitsDetailById(this.id).then((res) => {
        console.log("详情", res);
        this.productDetail = res.data.data;
       
        // this.detailsdata.addTime = this.detailsdata.addTime.slice(0, 10);
      });
      await GetDTExhibitsDetail(this.id).then((res) => {
        this.productLikeFavDetail = res.data.data;
        const ids = this.myExhibitList.map((i) => i.conferenceCompanyExhibitsId);
        console.log(ids);
         if (ids.includes(this.productLikeFavDetail.conferenceCompanyExhibitsId)) {
             this.productLikeFavDetail.isFav = true;
          } else {
             this.productLikeFavDetail.isFav = false;
          }
        
      });
      // await gethotconsultinglist().then((res) => {
      //   console.log("推荐资讯", res);
      //   this.hotconsultlist = res;
      // });
      getDTExhibitsListByConferenceCompanyId(this.conferenceCompanyId).then(
        (res) => {
          this.productsList = res.data.data;
        }
      );
     
    
    },
    goProductsDetails(item) {
      console.log(item);
      let routeData = this.$router.resolve({
        name: "productDetails",
        query: {
          id: item.exhibitId,
          conferenceCompanyId: this.conferenceCompanyId,
        },
      });
      window.open(routeData.href);
    },
    clickExpertLike(conferenceCompanyExhibitsId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        let data = {
          userId: localStorage.getItem("userId")-0,
          conferenceCompanyExhibitsId: conferenceCompanyExhibitsId,
        };
        exhibitsLike(data).then((res) => {
          console.log(res);
          if (res.data.code == "400") {
            exhibitsLikeDown(data).then((res) => {
              this.start();
            });
          } else {
            this.start();
          }
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能点赞",
          type: "warning",
        });
      }
      // let data = {
      //   userId: 1,
      //   conferenceCompanyExhibitsId: conferenceCompanyExhibitsId,
      // };
      // exhibitsLike(data).then((res) => {
      //   console.log(res);
      //   this.start();
      // });
    },
    clickProjductsFav(conferenceCompanyExhibitsId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        let data = {
          userId: localStorage.getItem("userId")-0,
          conferenceCompanyExhibitsId: conferenceCompanyExhibitsId,
        };
        exhibitsFav(data).then((res) => {
          console.log(res);
          if (res.data.code == "400") {
            exhibitsFavDown(data).then((res) => {
              this.start();
            });
          } else {
            this.start();
          }
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能点赞",
          type: "warning",
        });
      }
      // let data = {
      //   userId: 1,
      //   conferenceCompanyExhibitsId: conferenceCompanyExhibitsId,
      // };

      // exhibitsFav(data).then((res) => {
      //   console.log(res);
      //   this.start();
      // });

      // else{
      //   exhibitsFavDown(data).then((res) =>{
      //   console.log(res
      //   )
      //   this.start();
      //   })
      // }
    },
  },
  mounted() {
    this.conferenceCompanyId = this.$route.query.conferenceCompanyId - 0;
    this.id = this.$route.query.id;
    this.start();
  },
};
</script>

<style lang="less" scoped>
.DetailsPage {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  .DetailsPage-left {
    width: 100%;
    margin-right: 20px;
    .DetailsPage-left-header {
      display: flex;
      background: white;
      height: 56px;
      line-height: 56px;
      margin-bottom: 20px;
      div {
        margin: 0 5px;
          a{
          color: #C1C1C1;
        }
      }
       .gang{
          color: #C1C1C1;
        }
      div:nth-child(1) {
        margin-left: 20px;
      }
      div:nth-child(4),
      :nth-child(2) {
        cursor: pointer;
      }
      :hover{
        color: #171717;
      }
    }
    .DetailsPage-left-main {
      background: white;
      padding: 40px;
      .img-box {
        text-align: center;
        img {
          width: 156px;
          height: 156px;
          // border-radius: 50%;
          overflow: hidden;
          margin: 0 auto;
        }
      }
      .main-body {
        margin: 20px 0;
        .main-body-header {
          background: white;
          display: flex;
          justify-content: space-between;
          padding: 20px 20px;
          border-bottom: 1px solid #e9e9e9;
          .newmessage {
            font-size: 20px;
            .icon {
              width: 18px;
              height: 18px;
            }
          }
        }
       .main-body-content {
          margin: 30px;
                /deep/img {
          margin: 0 auto!important;
          width: 100% !important;
        }
        /deep/p{
          color: #464646 !important;
          font-size: 16px !important;
          line-height: 1.75rem !important;
            font-family: "PINGFANG-MEDIUM" !important;
            span{
                   color: #464646 !important;
          font-size: 16px !important;
          line-height: 1.75rem !important;
            font-family: "PINGFANG-MEDIUM" !important;
            }
        }
        }
      }
      .speaker-info-box {
        h2 {
          margin-bottom: 34px;
          padding-bottom: 0;
        }
        h3 {
          font-family: "Gotham A", "Gotham B", sans-serif;
          font-style: normal;
          font-weight: 700;
          margin-top: 0;
          margin-bottom: 15px;
          font-size: 18px;
          line-height: 22px;
          color: #000;
        }
      }
      .title {
        font-size: 30px;
        line-height: 45px;
        // font-weight: bold;
      }
      .Article-information {
        margin: 10px 0;
        color: #ababab;
        div:nth-child(1) {
          display: flex;
        }
        div {
          margin-right: 50px;
        }
        .iconfont {
          margin-right: 10px;
          color: #848484;
        }
        display: flex;
      }
      .text-statement {
        background: #f6f6f6;
        padding: 20px;
        color: #888;
      }
      .main-text {
        margin: 20px 0;
        line-height: 34px;
        /deep/img {
          margin: 0 auto;
        }
      }
      .text-operation {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        // div:nth-child(1) {
        //   position: absolute;
        //   left: 0px;
        // }
        // .iconfont {
        //   font-size: 20px;
        //   color: #a8a8a8;
        //   margin-left: 10px;
        //   margin-right: 5px;
        //   cursor: pointer;
        // }
        // div {
        //   padding: 10px 20px;
        //   background: #f5f6f6;
        //   color: #314483;
        //   font-size: 14px;
        //   cursor: pointer;
        //   margin-right: 20px;
        //   span {
        //     margin-left: 5px;
        //   }
        // }
        div {
                padding: 10px 25px;
                background: #fff;
                color: #525252;
                font-size: 14px;
                margin-right: 20px;
                border: 1px solid #EDEDED;
                display: flex;
                cursor: pointer;
    align-items: flex-end;
                span {
                  margin-left: 10px;
                }
              }
      }
    }
  }
  .DetailsPage-right {
    width: 40%;
    min-width: 360px;
    .consulting-header {
      padding: 20px 20px;
      background: white;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .icon {
        width: 18px;
        height: 18px;
      }
      div:nth-child(1) {
        font-size: 20px;
        font-weight: 400;
      }
      div:nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        color: #9a9a9a;
      }
    }
    .productsList-items {
      padding: 20px;
      background: white;
      text-align: center;
      display: flex;
      border-bottom: 1px solid #e9e9e9;
      cursor: pointer;
      .item-left {
        width: 100px;
        img {
          width: 100%;
          // border-radius: 50%;
        }
      }
      .item-mid {
        width: 100%;
        text-align: left;
        margin: 10px 20px;
        p {
          margin: 0;
          margin-bottom: 10px;
          font-size: 12px;
        }
        p:nth-child(1) {
          font-size: 14px;
          font-weight: bold;
        }
        .type {
          font-size: 12px;
          background: #314483;
          padding: 0.1875rem 0.625rem;
          text-align: center;
          color: white;
          line-height: 1.25rem;
        }
        .sub-box {
          display: flex;
          div {
            margin-right: 10px;
            font-size: 12px;
            color: #314483;
            padding: 4px 5px;
            border: 1px solid #314483;
            cursor: pointer;
          }
        }
      }

      // &:nth-child(odd) {
      //   background: #e9e9e950;
      // }

      .item-title {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
      }
    }
    .consulting-items {
      &:nth-child(odd) {
        background: #e9e9e950;
      }
      padding: 20px;
      background: white;
      display: flex;
      align-items: center;
      .item-left {
        width: 100px;
        img {
          width: 100%;
          border-radius: 50%;
        }
      }
      .item-mid {
        width: 100%;

        margin: 0 10px;
        p {
          margin: 0;
          margin-bottom: 10px;
          font-size: 12px;
        }
        p:nth-child(1) {
          font-size: 14px;
          font-weight: bold;
        }
        .sub-box {
          display: flex;
          div {
            margin-right: 10px;
            font-size: 12px;
            color: #314483;
            padding: 4px 5px;
            border: 1px solid #314483;
            cursor: pointer;
          }
        }
      }
      .item-right {
        width: 120px;
        background: white;
        text-align: center;
        padding: 6px 0px;
        font-size: 13px;
        color: #616161;
        cursor: pointer;
        border: 1px solid #616161b2;
        .iconfont {
          font-weight: bold;
        }
      }
    }
  }
}
</style>